import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyStatement from "../components/PrivacyStatement/PrivacyStatement"

const PrivacyStatementPage = () => {
  const { t } = useTranslation()
  return (
    <Layout showHeaderBG={true}>
      <SEO title={t("Privacy Statement")} />
      <PrivacyStatement  />
    </Layout>
  )
}

export default PrivacyStatementPage
