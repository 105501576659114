import React from "react"
import BannerShort from "../BannerShort/BannerShort"
import { LeftArticle } from "../LeftArticle/LeftArticle"


const bannerTitle = "Privacy Statement"
const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/TermsAndPolicyPages/background-privacy-statement.jpg`
const privacyStatement = [
    {
        description: `<p>At Neurond, we are committed to protecting your privacy and ensuring the security of your personal data. As a world-class provider of Artificial Intelligence and Data Science services, we strive to empower individuals and organizations while maintaining the utmost respect for your privacy rights.</p>`
    },
    {
        title: "1. Your Information Is Safe With Us",
        description: `<p>We value your trust and want you to feel confident using our website. Rest assured that we do not require you to disclose any personal data to access our home page or browse our site. Your anonymity is respected throughout your visit.</p>`
    },
    {
        title: "2. Data Collection and Purpose Specification",
        description: `<p>When you choose to use our services, we may collect personal data that you voluntarily provide to us. We do not obtain information from other sources, such as public records or private organizations, ensuring your data remains confidential.</p>`
    },
    {
        title: "3. Confidentiality and Security",
        description: `<p>We take your data security seriously. To safeguard your personal data, we offer the option of using a secure transmission method when providing your information. Our implemented security policies, rules, and technical measures protect your data from unauthorized access, improper use or disclosure, unauthorized modification, and unlawful destruction or accidental loss. Our employees and data processors adhere to strict confidentiality standards, and we never disclose your personal data to State institutions or authorities unless required by law or regulation.</p>`
    },
    {
        title: "4. The Services And Links of Our Website",
        description: `<p>Our website does not enable visitor communication or allow posting information for public access, ensuring your privacy is upheld.</p>`
    },
    {
        title: "5. Automatic Collection of Information",
        description: `<p>We do not automatically log personal data, nor do we link any automatically logged information to specific individuals. Additionally, we do not use cookies to store personal data or link non-personal information stored in cookies with individual data.</p>`
    },
    {
        title: "6. Children's Privacy",
        description: `<p>We do not knowingly collect personal data from children and do not take specific steps to protect the privacy of children who might inadvertently disclose their information to us.</p>`
    },
    {
        title: "7. Disclosure",
        description: `<p>Your personal data is not disclosed to our subsidiaries or other organizations. We value your privacy and maintain the confidentiality of your data at all times.</p>`
    },
    {
        title: "8. Contact Information",
        description: `<p>If you have any inquiries, comments, or concerns regarding our Privacy Statement, please feel free to reach out to us at contact@neurond.com. We are dedicated to providing a safe and secure online experience for all our visitors.</p>`
    }
]

const PrivacyStatement = () => (
    <section>
        <BannerShort title={bannerTitle} backgroundImage={bannerImg} />
        <div className="wrapper align-items-start mb-5">
        {
            privacyStatement.map((item, index) => {
                return (
                    <LeftArticle data={item} />
                )
            })
        }
        </div>
    </section>
)

export default PrivacyStatement
